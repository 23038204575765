import './Home.css';
import React from 'react';

function Home() {
    return (
    <div className="page">
        <div id="whitebox">
          <p id="welcome">Welcome to Leather!</p>
        </div>
    </div>
    );
}

export default Home;
