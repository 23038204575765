import './Home.css';
import React from 'react';

function NotFound() {
    return (
        <div className="page">
            <div id="whitebox">
                <p id="welcome">Page not Found.</p>
            </div>
        </div>
    );
}

export default NotFound;
