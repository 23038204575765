import './Home.css';
import React from 'react';

function AccountInvalid() {
    return (
        <div className="page">
            <div id="whitebox">
                <p id="welcome">Invalid token.</p>
                <p id="signed-up">Please register again.</p>
            </div>
        </div>
    );
}

export default AccountInvalid;

