import './Home.css';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

function Redirect() {

    const [searchParams] = useSearchParams();
    let query = searchParams.get("token")

    useEffect(() => {
        window.location.href = "https://afternoon-bayou-00587.herokuapp.com/api/account/registrationConfirm?token="+query;
    });

    return (
        <div className="page">
            <div id="whitebox">
                <p id="welcome">Loading...</p>
            </div>
        </div>
    );
}

export default Redirect;
