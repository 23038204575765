import './Home.css';
import React from 'react';

function AccountSuccess() {
  return (
    <div className="page">
        <div id="whitebox">
          <p id="welcome">Welcome to Leather!</p>
          <p id="signed-up">You're all signed up and can log in.</p>
        </div>
    </div>
  );
}

export default AccountSuccess;

