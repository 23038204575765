import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import Home from './Home';
import AccountSuccess from './AccountSuccess';
import AccountInvalid from './AccountInvalid';
import AccountExpired from './AccountExpired';
import ResetPassword from './ResetPassword';
import ResetEmail from './ResetEmail';
import NotFound from './NotFound';
import Redirect from './Redirect';
import CookiePolicy from './CookiePolicy';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route exact path="/invalid" element={<AccountInvalid />}/>
        <Route exact path="/success" element={<AccountSuccess />}/>
        <Route exact path="/expired" element={<AccountExpired />}/>
        <Route path="/reset-password" element={<ResetPassword />}/>
        <Route path="/reset-email" element={<ResetEmail />}/>
        <Route path="/confirmation" element={<Redirect />}/>
        <Route path="/cookie-policy" element={<CookiePolicy />}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
        <Route path="/terms-and-conditions" element={<TermsAndConditions />}/>
        <Route path="*" element={<NotFound />}/>
      </Routes>
    </Router>
  );
}

export default App;
