import './Home.css';
import React, { useState } from "react";
import { useSearchParams } from 'react-router-dom';

function ResetEmail() {
    const [email1, setEmail1] = useState("");
    const [email2, setEmail2] = useState("");
    const [errorClass, setErrorClass] = useState("hidden")
    const [errorText, setErrorText] = useState("Emails don't match.")
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    const handleSubmit = (e) => {
        e.preventDefault();
        if(email1 === email2) {

            let data = new FormData();
            data.append('token', token);
            data.append('emailAddress', email1);
    
            fetch("https://afternoon-bayou-00587.herokuapp.com/api/account/updateEmail/", {
                method: 'POST',
                body: data
            })
                .then(res => res.text())
                .then((body) => {
                    if (body === 'Updated email') {
                        setErrorText("Email reset.");
                        setErrorClass("");
                    } 
                    else if (body === 'Invalid token') {
                        setErrorText("Token expired. Please reset your email again.");
                        setErrorClass("");
                    }
                    else if (body === 'Failed to update password') {
                        setErrorText("Something went wrong. Please reset again.");
                        setErrorClass("");
                    }
                    else if (body === 'Email already taken') {
                        setErrorText("Email already exists.");
                        setErrorClass("");
                    }
                })
                .catch(err => {
                    console.log(err);
                });

        } else {
            setErrorClass("");
        }
    }

    return (
        <div className="page">
            <div id="input-box">
                <form id="password-reset" onSubmit={handleSubmit}>
                    <label id="password-label">Enter New Email</label>
                    <input className="password-input" type="email" placeholder="Email" autoComplete="new-email" required onChange={e => setEmail1(e.target.value)}/>
                    <input className="password-input" type="email" placeholder="Re-enter Email" autoComplete="new-email" required onChange={e => setEmail2(e.target.value)}/>
                    <p id="error-msg" className={errorClass}>{errorText}</p>
                    <input className="password-submit" type="submit"/>
                </form>
            </div>
        </div>
    );
}

export default ResetEmail;

